.d1wv5w65{font-size:var(--font-size-xxxs);line-height:1.55;-webkit-text-decoration:none;text-decoration:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:var(--gsu-grey);margin-top:2px;}.d1wv5w65:before{content:'';-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;height:1px;border-top:1px solid var(--gsu-grey20);margin-right:10px;}.d1wv5w65:after{content:'';-webkit-flex:1 1 0%;-ms-flex:1 1 0%;flex:1 1 0%;border-top:1px solid var(--gsu-grey20);margin-left:10px;}
.h16y60zp{margin:0 auto;max-width:150px;}
.t1cpirn5{font-size:var(--font-size-md);}@media screen and (min-width:1280px){.t1cpirn5{font-size:var(--font-size-lg);}}
.m7kisr6{text-align:center;padding:var(--element-padding-lg) var(--element-padding-md) calc(var(--element-padding-lg) - var(--element-padding-xs)) var(--element-padding-md);}@media screen and (min-width:1280px){.m7kisr6{padding:var(--element-padding-xxl) 56px calc(var(--element-padding-xxl) - var(--element-padding-md)) 56px;}}
.bm5pxeq{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.m8ojjza{text-align:center;padding:calc(var(--element-padding-lg) - var(--element-padding-xs)) calc(var(--element-padding-md) - var(--element-padding-xs));}@media screen and (min-width:1280px){.m8ojjza{padding:calc(var(--element-padding-xxl) - var(--element-padding-md));}}
.cgvpf1j{background-color:var(--gsu-tertiary20);border-radius:var(--border-radius-lg);padding:var(--element-padding-sm);text-align:left;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.cw8h0s8{width:15px;height:15px;-webkit-flex:none;-ms-flex:none;flex:none;margin-top:var(--element-spacing-xxxs);margin-right:var(--element-spacing-xs);}
@media (min-width:980px){.h16vh9rn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.h16vh9rn > *{margin:0 var(--element-spacing-xxxs);}}
.b19sb80o{width:100%;margin-top:var(--element-padding-xxxs);margin-bottom:var(--element-padding-xxxs);}
.vf5vs9k{margin:calc(-1 * var(--element-padding-xs));margin-bottom:0;}@media screen and (min-width:1280px){.vf5vs9k{margin:calc(-1 * var(--element-padding));margin-bottom:0;}}.vf5vs9k > div{border-top-right-radius:var(--border-radius-rem);border-top-left-radius:var(--border-radius-rem);overflow:hidden;}@media screen and (min-width:600px){.vf5vs9k > div{border-top-right-radius:var(--border-radius-xl);border-top-left-radius:var(--border-radius-xl);}}
