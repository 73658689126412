@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  position: relative;
  color: var(--gsu-black80);
  font-family: 'Inter', Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --gsu-tertiary-brand-darkened: #40392c;
  --gsu-white: #ffffff;
  --gsu-dark-grey-hover: #3a3a52;
  --gsu-dark-grey: #4a4a68;

  /* NEW COLOR SET - Apr 22 */
  --gsu-primary-dark: #10123a;
  --gsu-primary: #181b56;
  --gsu-primary80: #2c318a;
  --gsu-primary60: #3c44d5;
  --gsu-primary40: #88aaff;
  --gsu-primary30: #b2c8ff;
  --gsu-primary20: #e0e9ff;
  --gsu-primary10: #f0f4ff;
  --gsu-primary5: #f8faff;
  --gsu-secondary-dark: #db3c00;
  --gsu-secondary: #ff4600;
  --gsu-secondary60: #ff7845;
  --gsu-secondary20: #feddd1;
  --gsu-secondary10: #fcece7;
  --gsu-tertiary40: #e0d5c3;
  --gsu-tertiary20: #efe7db;
  --gsu-tertiary10: #f9f5ef;
  --gsu-black: #0e0e10;
  --gsu-black80: #2d2d32;
  --gsu-black60: #464650;
  --gsu-black-translucent: #0e0e1050;
  --gsu-grey: #757582;
  --gsu-grey80: #8c8ca1;
  --gsu-grey60: #b1b1bf;
  --gsu-grey20: #eaeaef;
  --gsu-grey10: #f7f7fa;
  --gsu-white: #ffffff;
  --gsu-green: #23a868;
  --gsu-green10: #eaf9f2;
  --gsu-red: #e61535;
  --gsu-red10: #fff2f1;
  --gsu-yellow: #fbb02a;
  --gsu-yellow10: #fff9ee;
  --gsu-cat-business: #565760;
  --gsu-cat-communication: #2b7ea5;
  --gsu-cat-cooking: #db3a33;
  --gsu-cat-creativity: #815cc8;
  --gsu-cat-government: #9d6c3f;
  --gsu-cat-health: #c9428e;
  --gsu-cat-social: #474980;
  --gsu-cat-technology: #188651;
  --gsu-cat-travel: #5567cf;

  --element-spacing: 24px;
  --element-spacing-xxxs: 4px;
  --element-spacing-xxs: 8px;
  --element-spacing-xs: 12px;
  --element-spacing-sm: 16px;
  --element-spacing-md: 24px;
  --element-spacing-lg: 32px;
  --element-spacing-xl: 48px;
  --element-spacing-xxl: 64px;
  --element-spacing-xxxl: 96px;

  --element-padding: 24px;
  --element-padding-xxxs: 4px;
  --element-padding-xxs: 8px;
  --element-padding-xs: 12px;
  --element-padding-sm: 16px;
  --element-padding-md: 24px;
  --element-padding-lg: 32px;
  --element-padding-xl: 48px;
  --element-padding-xxl: 64px;
  --element-padding-xxxl: 96px;
  --element-padding-xxxxl: 108px;

  --border-radius: 12px;
  --border-radius-xl: 32px;
  --border-radius-lg: 24px;
  --border-radius-md: 8px;
  --border-radius-sm: 6px;
  --border-radius-rem: 1rem;

  --font-size-xxxs: 12px;
  --font-size-xxs: 14px;
  --font-size-xs: 16px;
  --font-size-sm: 18px;
  --font-size-md: 20px;
  --font-size-lg: 24px;
  --font-size-xl: 32px;
  --font-size-xxl: 40px;
  --font-size-xxxl: 48px;

  --line-height-xxxs: 16px;
  --line-height-xxs: 20px;
  --line-height-xs: 22px;
  --line-height-sm: 24px;
  --line-height-md: 26px;
  --line-height-lg: 32px;
  --line-height-xl: 40px;
  --line-height-xxl: 48px;
  --line-height-xxxl: 56px;

  --font-weight-sm: 400;
  --font-weight-md: 500;
  --font-weight-semi-bold: 600;
  --font-weight-lg: 700;
  --font-weight-desktop-lg: 600;

  --bottom-nav-height: 56px;
  --desktop-nav-width: 100px;
  --desktop-header-height: 96px;
  --desktop-nav-menu-more-width: 450px;
  --page-max-width: 1600px;

  --rem-xxxs: 0.125rem;
  --rem-xxs: 0.25rem;
  --rem-xs: 0.375rem;
  --rem-sm: 0.5rem;
  --rem-md: 0.625rem;
  --rem-lg: 0.75rem;
  --rem-xl: 0.875rem;
  --rem-xxl: 1rem;

  --width-xxs: 18px;
  --width-xs: 20px;
  --height-xs: 20px;

  --transition-fast: 0.2s;
  --transition: 0.4s;
  --transition-slow: 0.6s;

  --breakpoint-smallTablet: 600px;
  --breakpoint-tablet: 980px;
  --breakpoint-desktop: 1280px;
}

form {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

*::selection {
  background: var(--gsu-secondary60);
  color: var(--gsu-black80);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  padding-inline-start: 20px;
}

/* Page Max Width */

.page-max-width {
  width: 100%;
  max-width: var(--page-max-width);
  margin: auto;
  padding: 0;
}

/* Padding utility */
.p-xxxs {
  padding: var(--element-padding-xxxs) !important;
}

.p-xxs {
  padding: var(--element-padding-xxs) !important;
}

.p-xs {
  padding: var(--element-padding-xs) !important;
}

.p {
  padding: var(--element-padding) !important;
}

.p-sm {
  padding: var(--element-padding-sm) !important;
}

.p-md {
  padding: var(--element-padding-md) !important;
}

.p-lg {
  padding: var(--element-padding-lg) !important;
}

.pt {
  padding-top: var(--element-padding) !important;
}

.pt-xxxs {
  padding-top: var(--element-padding-xxxs) !important;
}

.pt-xxs {
  padding-top: var(--element-padding-xxs) !important;
}

.pt-xs {
  padding-top: var(--element-padding-xs) !important;
}

.pt-sm {
  padding-top: var(--element-padding-sm) !important;
}

.pt-md {
  padding-top: var(--element-padding-md) !important;
}

.pt-lg {
  padding-top: var(--element-padding-lg) !important;
}

.pt-xl {
  padding-top: var(--element-padding-xl) !important;
}

.pt-xxl {
  padding-top: var(--element-padding-xxl) !important;
}

.pt-xxxl {
  padding-top: var(--element-padding-xxxl) !important;
}

.pt-xxxxl {
  padding-top: var(--element-padding-xxxxl) !important;
}

.pr {
  padding-right: var(--element-padding) !important;
}

.pr {
  padding-right: var(--element-padding) !important;
}

.pr-xxs {
  padding-right: var(--element-padding-xxs) !important;
}

.pr-xs {
  padding-right: var(--element-padding-xs) !important;
}

.pr-sm {
  padding-right: var(--element-padding-sm) !important;
}

.pr-md {
  padding-right: var(--element-padding-md) !important;
}

.pr-lg {
  padding-right: var(--element-padding-lg) !important;
}

.pr-xl {
  padding-right: var(--element-padding-xl) !important;
}

.pr-xxl {
  padding-right: var(--element-padding-xxl) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb {
  padding-bottom: var(--element-padding) !important;
}

.pb-xs {
  padding-bottom: var(--element-padding-xs) !important;
}

.pb-sm {
  padding-bottom: var(--element-padding-sm) !important;
}

.pb-md {
  padding-bottom: var(--element-padding-md) !important;
}

.pb-lg {
  padding-bottom: var(--element-padding-lg) !important;
}

.pb-xl {
  padding-bottom: var(--element-padding-xl) !important;
}

.pb-xxl {
  padding-bottom: var(--element-padding-xxl) !important;
}

.pl {
  padding-left: var(--element-padding) !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-xxs {
  padding-left: var(--element-padding-xxs) !important;
}

.pl-xs {
  padding-left: var(--element-padding-xs) !important;
}

.pl-sm {
  padding-left: var(--element-padding-sm) !important;
}

.pl-md {
  padding-left: var(--element-padding-md) !important;
}

.pl-lg {
  padding-left: var(--element-padding-lg) !important;
}

.pl-xl {
  padding-left: var(--element-padding-xl) !important;
}

.pl-xxl {
  padding-left: var(--element-padding-xxl) !important;
}

.px {
  padding-left: var(--element-padding) !important;
  padding-right: var(--element-padding) !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-xs {
  padding-left: var(--element-padding-xs) !important;
  padding-right: var(--element-padding-xs) !important;
}

.px-sm {
  padding-left: var(--element-padding-sm) !important;
  padding-right: var(--element-padding-sm) !important;
}

.px-md {
  padding-left: var(--element-padding-md) !important;
  padding-right: var(--element-padding-md) !important;
}

.px-lg {
  padding-left: var(--element-padding-lg) !important;
  padding-right: var(--element-padding-lg) !important;
}

.px-xl {
  padding-left: var(--element-padding-xl) !important;
  padding-right: var(--element-padding-xl) !important;
}

.px-xxl {
  padding-left: var(--element-padding-xxl) !important;
  padding-right: var(--element-padding-xxl) !important;
}

.py {
  padding-top: var(--element-padding) !important;
  padding-bottom: var(--element-padding) !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-xxs {
  padding-top: var(--element-padding-xxs) !important;
  padding-bottom: var(--element-padding-xxs) !important;
}

.py-xs {
  padding-top: var(--element-padding-xs) !important;
  padding-bottom: var(--element-padding-xs) !important;
}

.py-sm {
  padding-top: var(--element-padding-sm) !important;
  padding-bottom: var(--element-padding-sm) !important;
}

.py-md {
  padding-top: var(--element-padding-md) !important;
  padding-bottom: var(--element-padding-md) !important;
}

.py-lg {
  padding-top: var(--element-padding-lg) !important;
  padding-bottom: var(--element-padding-lg) !important;
}

.py-xl {
  padding-top: var(--element-padding-xl) !important;
  padding-bottom: var(--element-padding-xl) !important;
}

.py-xxl {
  padding-top: var(--element-padding-xxl) !important;
  padding-bottom: var(--element-padding-xxl) !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

/* Margin utility */
.mt {
  margin-top: var(--element-spacing) !important;
}

.mt-xxxs {
  margin-top: var(--element-spacing-xxxs) !important;
}

.mt-xxs {
  margin-top: var(--element-spacing-xxs) !important;
}

.mt-xs {
  margin-top: var(--element-spacing-xs) !important;
}

.mt-sm {
  margin-top: var(--element-spacing-sm) !important;
}

.mt-md {
  margin-top: var(--element-spacing-md) !important;
}

.mt-lg {
  margin-top: var(--element-spacing-lg) !important;
}

.mt-xl {
  margin-top: var(--element-spacing-xl) !important;
}

.mt-xxl {
  margin-top: var(--element-spacing-xxl) !important;
}

.mr {
  margin-right: var(--element-spacing) !important;
}

.mr-xxxs {
  margin-right: var(--element-spacing-xxxs) !important;
}

.mr-xxs {
  margin-right: var(--element-spacing-xxs) !important;
}

.mr-xs {
  margin-right: var(--element-spacing-xs) !important;
}

.mr-sm {
  margin-right: var(--element-spacing-sm) !important;
}

.mr-md {
  margin-right: var(--element-spacing-md) !important;
}

.mr-lg {
  margin-right: var(--element-spacing-lg) !important;
}

.mr-xl {
  margin-right: var(--element-spacing-xl) !important;
}

.mr-xxl {
  margin-right: var(--element-spacing-xxl) !important;
}

.mb {
  margin-bottom: var(--element-spacing) !important;
}

.mb-xxxs {
  margin-bottom: var(--element-spacing-xxxs) !important;
}

.mb-xxs {
  margin-bottom: var(--element-spacing-xxs) !important;
}

.mb-xs {
  margin-bottom: var(--element-spacing-xs) !important;
}

.mb-sm {
  margin-bottom: var(--element-spacing-sm) !important;
}

.mb-md {
  margin-bottom: var(--element-spacing-md) !important;
}

.mb-lg {
  margin-bottom: var(--element-spacing-lg) !important;
}

.mb-xl {
  margin-bottom: var(--element-spacing-xl) !important;
}

.mb-xxl {
  margin-bottom: var(--element-spacing-xxl) !important;
}

.ml {
  margin-left: var(--element-spacing) !important;
}

.ml-xxxs {
  margin-left: var(--element-spacing-xxxs) !important;
}

.ml-xxs {
  margin-left: var(--element-spacing-xxs) !important;
}

.ml-xs {
  margin-left: var(--element-spacing-xs) !important;
}

.ml-sm {
  margin-left: var(--element-spacing-sm) !important;
}

.ml-md {
  margin-left: var(--element-spacing-md) !important;
}

.ml-lg {
  margin-left: var(--element-spacing-lg) !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.m-auto {
  margin: auto;
}

/* Font color utility */
.fc-primary {
  color: var(--gsu-primary) !important;
}

.fc-secondary {
  color: var(--gsu-secondary) !important;
}

.fc-primary-30 {
  color: var(--gsu-primary30) !important;
}

.fc-tertiary {
  color: var(--gsu-tertiary40) !important;
}

.fc-grey {
  color: var(--gsu-grey) !important;
}

.fc-grey-hover {
  color: var(--gsu-grey) !important;
}

.fc-dark {
  color: var(--gsu-black80) !important;
}

.fc-dark-grey {
  color: var(--gsu-dark-grey) !important;
}

.bg-secondary {
  background-color: var(--gsu-secondary) !important;
}

.bg-tertiary-20 {
  background-color: var(--gsu-tertiary10) !important;
}

.bg-modal-screen {
  background-color: var(--gsu-black-translucent);
}

/* Text align utility */
.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

/* Text size utility */

.fs-xxxl {
  font-size: var(--font-size-xxxl) !important;
  line-height: var(--line-height-xxxl) !important;
}

.fs-xxl {
  font-size: var(--font-size-xxl) !important;
  line-height: var(--line-height-xxl) !important;
}

.fs-xl {
  font-size: var(--font-size-xl) !important;
  line-height: var(--line-height-xl) !important;
}

.fs-lg {
  font-size: var(--font-size-lg) !important;
  line-height: var(--line-height-lg) !important;
}

.fs-md {
  font-size: var(--font-size-md) !important;
  line-height: var(--line-height-md) !important;
}

.fs-sm {
  font-size: var(--font-size-sm) !important;
  line-height: var(--line-height-sm) !important;
}

.fs-xs {
  font-size: var(--font-size-xs) !important;
  line-height: var(--line-height-xs) !important;
}

.fs-xxs {
  font-size: var(--font-size-xxs) !important;
  line-height: var(--line-height-xxs) !important;
}

.fs-xxxs {
  font-size: var(--font-size-xxxs) !important;
  line-height: var(--line-height-xxxs) !important;
}
.fw-semi-bold {
  font-weight: var(--font-weight-semi-bold) !important;
}

.fw-bold {
  font-weight: var(--font-weight-lg) !important;
}

.tt-up {
  text-transform: uppercase !important;
}

.bt-accent-hover {
  border-top: 1px solid var(--gsu-grey20);
}

.bb-accent-hover {
  border-bottom: 1px solid var(--gsu-grey20);
}

.w-100 {
  width: 100%;
}

/* Height Utility. NOTE: Please use sparingly, only if necessary */
.mvh-80 {
  min-height: 80vh;
}

.mvh-50 {
  min-height: 50vh;
}

.maxvh-70 {
  max-height: 70vh;
}

.maxw-290 {
  max-width: 290px;
}

.h-100 {
  height: 100%;
}

.z-index-l {
  z-index: 1;
}

.disabled-click {
  pointer-events: none !important;
}

.translucent {
  opacity: 0.6;
}

/* Makes a container and it's children responsive with grid layout */
.grid {
  width: 100%;
  display: grid;
  grid-auto-flow: dense;
  grid-gap: var(--element-spacing-sm);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

@media (min-width: 1280px) {
  .grid {
    grid-gap: var(--element-spacing-md);
  }
}

.gridAside {
  display: grid;
}

@media (min-width: 980px) {
  .gridAside {
    grid-gap: var(--element-spacing-xl);
    grid-template-columns: [first] 8fr [second] 4fr;
  }
}

@media (min-width: 1280px) {
  .gridAside {
    grid-gap: var(--element-spacing-xxl);
  }
}

/* Makes a container and it's children responsive with flex-grid layout */
.flex-grid {
  --grid-gap: var(--element-spacing);
  display: flex;
  flex-wrap: wrap;
  gap: var(--grid-gap);
  width: 100%;
}

.flex-grid > .gsu-card {
  flex: 1 !important;
}

@media screen and (min-width: 1280px) {
  .flex-grid {
    gap: var(--element-spacing-md);
  }
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.flex-grid > * {
  flex: 0 1 auto;
}

.flex-center {
  display: flex !important;
  align-items: center !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.gap-sm {
  gap: var(--element-spacing-sm);
}

.gap-xs {
  gap: var(--element-spacing-xs);
}

.gap-xxs {
  gap: var(--element-spacing-xxs);
}

.gap-xxxs {
  gap: var(--element-spacing-xxxs);
}

.maxvw-1280 {
  max-width: 1280px !important;
  margin: auto !important;
}

.maxvw-1440 {
  max-width: 1440px !important;
  margin: auto !important;
}

.maxh-48 {
  max-height: 48px;
}

.hide {
  display: none;
}

.sticky-header {
  position: sticky;
}

.relative {
  position: relative;
}

.full-width {
  width: 100% !important;
}

/* Bleed background colour through psuedo elements to help where needed for
 * awkward transitional states or layouts that would otherwise require other hacks */
@media screen and (max-width: 980px) {
  .blt-sm:before {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: var(--element-spacing-sm);
    margin-top: calc(-1 * var(--element-spacing-sm));
    pointer-events: none;
  }

  .blb-sm:after {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--element-spacing-sm);
    margin-bottom: calc(-1 * var(--element-spacing-sm));
    pointer-events: none;
  }

  .blt-md:before {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: var(--element-spacing-md);
    margin-top: calc(-1 * var(--element-spacing-md));
    pointer-events: none;
  }

  .blb-md:after {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--element-spacing-md);
    margin-bottom: calc(-1 * var(--element-spacing-md));
    pointer-events: none;
  }

  .blt-lg:before {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: var(--element-spacing-sm);
    margin-top: calc(-1 * var(--element-spacing-sm));
    pointer-events: none;
  }

  .blb-lg:after {
    content: '';
    background: inherit;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--element-spacing-lg);
    margin-bottom: calc(-1 * var(--element-spacing-lg));
    pointer-events: none;
  }
}

.ellipsis-2L {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.ellipsis-1L {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.drop-shadow {
  box-shadow: 0px 16px 32px rgba(25, 23, 29, 0.04), 0px 8px 16px rgba(25, 23, 29, 0.04),
    0px 4px 8px rgba(25, 23, 29, 0.04), 0px 2px 4px rgba(25, 23, 29, 0.04), 0px 1px 2px rgba(25, 23, 29, 0.04);
}

.of-unset {
  overflow: unset !important;
}

/* TODO: Remove this when working on UI CleanUP III */
.pt-80 {
  padding-top: 80px;
}

.cursor-pt {
  cursor: pointer;
}

/* Reach-UI tabs */
[data-reach-tab-list],
/* headless ui tabs */
[data-headlessui-tab-list] {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid var(--gsu-grey20);
}

button[id^='headlessui-tabs-tab-'] {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-md);
  border: 0;
  background: transparent;
  height: 45px;
  cursor: pointer;
  outline: none;
}

button[id^='headlessui-tabs-tab-']:last-child {
  border-right: 0;
}

button[id^='headlessui-tabs-tab-'][data-headlessui-state='selected'] {
  font-weight: var(--font-weight-md);
  border-bottom: 5px solid var(--gsu-primary);
  color: var(--gsu-primary);
}

:root {
  --reach-dialog: 1;
}

[data-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

[data-dialog-content] {
  width: 50vw;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
